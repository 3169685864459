import _get from "lodash/get";
import React, { Component } from "react";
// import { w3cwebsocket as W3CWebSocket } from "websocket";
import { connect } from "react-redux";
import { setDevices, setNotifications } from "./actions/gps/deviceActions";
import { bindActionCreators } from "redux";

class SetApp extends Component {
  async componentDidMount() {
    const wsUrl = `wss://pdispatch.com/api/socket`;
    // const client = new W3CWebSocket(wsUrl);
    const client = new WebSocket(wsUrl);

    client.onopen = () => {};

    client.onmessage = (message) => {
      try {
        const gpsDevices = _get(this, "props.gpsDevices", []);
        const data = JSON.parse(message.data);

        console.log("\n\n  data ", data);
        const devices = data.devices || [];
        const positions = data.positions || [];
        const events = data.events || [];

        const _gpsDevices = gpsDevices.map((device) => {
          const _device = devices.find((item) => item.id === device.id);
          if (_device && _device.id) {
            device = _device;
          }

          const _position = positions.find(
            (item) => item.deviceId === device.id
          );
          if (_position && _position.id) {
            device.position = _position;
          }

          const _event = events.find((item) => item.deviceId === device.id);
          if (_event && _event.id) {
            device.event = _event;
            this.handleEvent(_event);
          }

          return device;
        });

        this.props.setDevices(_gpsDevices);
      } catch (e) {
        console.log(e);
      }
    };
  }

  handleEvent = (payload = {}) => {
    const dId = payload.deviceId;
    let { notifications = [], gpsDevices = [] } = this.props;

    payload.device = gpsDevices.find((item) => item.id === dId);

    notifications.push(payload);

    // console.log('notifications :: ', notifications);
    this.props.setNotifications(notifications);
  };

  render() {
    return null;
  }
}

class SocketApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }
  async componentDidMount() {
    try {
      const { token } = this.props;
      const sentData = {
        method: "GET",
        mode: "cors",
        header: new Headers({
          "Access-Control-Allow-Origin": "*",
        }),
      };
      if (token) {
        const url = `https://pdispatch.com/api/session?token=${token}`;
        // const response =
        await fetch(url, sentData);
        // console.log("response ", response);
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { token } = this.props;
    const url = `https://pdispatch.com/api/session?token=${token}`;
    if (!token) {
      return null;
    }
    return (
      <React.Fragment>
        <div id="gpsIFrame" style={{ height: 0 }}>
          {this.state.isLoaded ? (
            <SetApp
              gpsDevices={this.props.gpsDevices}
              setDevices={this.props.setDevices}
              notifications={this.props.notifications}
              setNotifications={this.props.setNotifications}
            />
          ) : (
            <iframe
              src={url}
              title="GPS Tracker"
              style={{ height: 0 }}
              onLoad={(e) => {
                setTimeout(() => {
                  this.setState({ isLoaded: true });
                }, 1000);
              }}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: _get(state, "authData.tracker.token"),
    notifications: _get(state, "deviceGpsData.notifications", []),
    notificationCount: _get(state, "deviceGpsData.notificationCount", 0),
    gpsDevices: _get(state, "deviceGpsData.devices", []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setDevices, setNotifications }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SocketApp);
